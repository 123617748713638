import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import Select from "react-select";
import { VARIABLES } from "../../../Constant";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import secureLocalStorage from "react-secure-storage";
import { TypeMasterContext } from "../../../Context/TypeMaster";
import { CandidateEntriesContext } from "../../../Context/CandidateEntriesContext";

const EditEducational = (props) => {
    console.log("props: ", props)

    const { educationType } = useContext(TypeMasterContext);
    const { getCandidateOtherData } = useContext(CandidateEntriesContext);

    const {
        handleSubmit,
        control,
        register,
        setValue,
        formState: { errors, isDirty },
        reset,
    } = useForm({
        defaultValues: {
            educations: [
                {
                    education_type: { value: "", label: "" },
                    passing_year: "",
                    board_name: "",
                    specialization: "",
                    percentage: "",
                    marksheet: "",
                    comments: "",
                },
            ],
        },
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: "educations",
        keyName: "key"
    });

    const userID = secureLocalStorage.getItem("userID");

    const [detailToBeRemoved, setDetailToBeRemoved] = useState([])

    const location = useLocation();

    const [isLoading, setIsLoading] = useState(false);
    const [submitMessage, setSubmitMessage] = useState(null);
    const [selectedEducationType, setSelectedEducationType] = useState(null);

    const handleEducationType = (e) => {
        setSelectedEducationType(e?.value);
    };

    const editedJobPost = props?.editpost

    const handleModalClose = () => {
        reset();
    };
    const removeDetails = (index, detail_id) => {
        if (detail_id) {
            Swal.fire({
                text: "Are you sure? You will not be able to recover this data!",
                showDenyButton: true,
                confirmButtonText: "Yes",
                denyButtonText: `No, cancel!`,
            }).then((result) => {
                if (result.isConfirmed) {
                    setDetailToBeRemoved([...detailToBeRemoved, detail_id])
                    remove(index)
                }
            });
        } else {
            remove(index)
        }

    }

    const onSubmit = (data) => {
        console.log(data)
        if (props.candidateData.length > 0) {
            let education = new FormData();
            education.append("count", data.educations.length);
            data.educations.map((edu, index) => {
                education.append(
                    `educationdetails-${index + 1}`,
                    JSON.stringify({
                        id: edu.id,
                        education_type: edu.education_type.value,
                        passing_year: edu.passing_year,
                        board_name: edu.board_name,
                        specialization: edu.specialization,
                        percentage: edu.percentage === "" ? 0 : edu.percentage,
                        comments: edu.comments,
                        candidate_job_id: props?.editpost?.id,
                    })
                );
                education.append(`attachment-${index + 1}`, edu.marksheet);
            });
            education.append("is_draft", 0);
            education.append("created_by_id", userID);
            education.append("updated_by_id", userID);
            education.append("details_to_be_removed", detailToBeRemoved);
            education.append("candidate_job_id", props?.editpost?.id);


            for (var pair of education.entries()) {
                console.log(pair);
            }

            const url = VARIABLES.url + `/api/educationdetails/`;

            let config = {
                method: "patch",
                maxBodyLength: Infinity,
                url: url,
                data: education,
            };

            setIsLoading(true);
            axios
                .request(config)
                .then((response) => {
                    setIsLoading(false);
                    // getCandidateOtherData(location?.state?.editpost?.id)
                    Swal.fire({
                        icon: "success",
                        title: "You have succesfully updated your education details."
                    }).then(() => {
                        document.getElementById("button-button-closeeducational").click();
                    });
                })
                .catch((error) => {
                    setIsLoading(false);
                    console.log(error);
                    Swal.fire({
                        icon: "error",
                        title: "Failed to update the education details"
                    })
                });
        } else {
            let education = new FormData();
            education.append("count", data.educations.length);
            data.educations.map((edu, index) => {
                education.append(
                    `educationdetails-${index + 1}`,
                    JSON.stringify({
                        id: edu.id,
                        education_type: edu.education_type.value,
                        passing_year: edu.passing_year,
                        board_name: edu.board_name,
                        specialization: edu.specialization,
                        percentage: edu.percentage === "" ? 0 : edu.percentage,
                        comments: edu.comments,
                        candidate_job_id: props?.editpost?.id,
                    })
                );
                education.append(`attachment-${index + 1}`, edu.marksheet);
            });
            education.append("is_draft", 0);
            education.append("created_by_id", userID);
            education.append("updated_by_id", userID);
            education.append("details_to_be_removed", detailToBeRemoved);
            education.append("candidate_job_id", props?.editpost?.id);


            for (var pair of education.entries()) {
                console.log(pair);
            }

            const url = VARIABLES.url + `/api/educationdetails/`;

            let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: url,
                data: education,
            };

            setIsLoading(true);
            axios
                .request(config)
                .then((response) => {
                    setIsLoading(false);
                    // getCandidateOtherData(location?.state?.editpost?.id)
                    Swal.fire({
                        icon: "success",
                        title: "You have succesfully updated your education details."
                    }).then(() => {
                        document.getElementById("button-button-closeeducational").click();
                    });
                })
                .catch((error) => {
                    setIsLoading(false);
                    console.log(error);
                    Swal.fire({
                        icon: "error",
                        title: "Failed to update the education details"
                    })
                });
        }
    }

    useEffect(() => {
        const storedData = props?.candidateData;
        console.log(storedData)
        if (storedData?.length > 0) {
            let values = {};
            values.educations = storedData;
            reset(values);
        } else if (storedData?.length === 0) {
            let values = {};
            values.educations = []
            reset(values);
        }
    }, [props?.candidateData]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setSubmitMessage(null);
        }, 5000);

        return () => clearTimeout(timeoutId);
    }, [submitMessage]);

    return (
        <>
            <div
                className="modal fade"
                id="educational"
                aria-hidden="true"
                aria-labelledby="exampleModalToggleLabel2"
                tabindex="-1"
            >
                <div className="modal-dialog">
                    <div className="modal-content w-100">
                        <div className="modal-header">
                            <h4 class="modal-title">Edit educational details</h4>
                            <button
                                type="button"
                                className="btn-close"
                                id="button-button-closeeducational"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={handleModalClose}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        {fields.map((test, index) => (
                                            <div key={index}>
                                                <div className="row gy-4">
                                                    <div className="form-group" style={{ display: "none" }}>
                                                        <input
                                                            {...register(`educations.${index}.id`)}
                                                            className="form-control"
                                                            type="text" hidden
                                                        />
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="form-label">
                                                                Qualification
                                                                <span className="fs-8 text-danger">*</span>
                                                            </label>
                                                            <Controller
                                                                render={({ field }) => (
                                                                    <Select
                                                                        {...field}
                                                                        options={educationType}
                                                                        isClearable={true}
                                                                        id="select-forms-education_type"
                                                                        onChange={(e) => {
                                                                            handleEducationType(e);
                                                                            field.onChange(e);
                                                                        }}
                                                                    />
                                                                )}
                                                                defaultValue=""
                                                                rules={{ required: true }}
                                                                name={`educations.${index}.education_type`}
                                                                control={control}
                                                            />
                                                            {errors.educations?.[index]?.education_type && (
                                                                <span className="fs-8 text-danger">
                                                                    Education Type is required
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="form-label">
                                                                Passing/Completion Year{" "}
                                                                <span className="fs-8 text-danger">*</span>
                                                            </label>
                                                            <input
                                                                {...register(`educations.${index}.passing_year`, {
                                                                    required: true,
                                                                    pattern: { value: /^\d+$/ },
                                                                    maxLength: 4,
                                                                    minLength: 4,
                                                                })}
                                                                defaultValue=""
                                                                className="form-control"
                                                                id="number-forms-passing_year"
                                                                type="number"
                                                                placeholder="Enter Passing Year"
                                                                onWheel={(e) => e.target.blur()}
                                                            />
                                                            {errors.educations?.[index]?.passing_year &&
                                                                errors.educations?.[index]?.passing_year.type ===
                                                                "required" && (
                                                                    <span className="fs-8 text-danger">
                                                                        Passing Year is required
                                                                    </span>
                                                                )}
                                                            {errors.educations?.[index]?.passing_year &&
                                                                errors.educations?.[index]?.passing_year.type ===
                                                                "pattern" && (
                                                                    <span className="fs-8 text-danger">
                                                                        Year cannot be negative or in decimal
                                                                    </span>
                                                                )}
                                                            {errors.educations?.[index]?.passing_year &&
                                                                errors.educations?.[index]?.passing_year.type ===
                                                                "maxLength" && (
                                                                    <span className="fs-8 text-danger">Invalid Year</span>
                                                                )}
                                                            {errors.educations?.[index]?.passing_year &&
                                                                errors.educations?.[index]?.passing_year.type ===
                                                                "minLength" && (
                                                                    <span className="fs-8 text-danger">Invalid Year</span>
                                                                )}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="form-label">
                                                                Board Name/University/Platform{" "}
                                                                <span className="fs-8 text-danger">*</span>
                                                            </label>
                                                            <input
                                                                {...register(`educations.${index}.board_name`, {
                                                                    required: true,
                                                                })}
                                                                defaultValue=""
                                                                className="form-control"
                                                                id="text-forms-board_name"
                                                                type="text"
                                                                placeholder="Enter Board Name/University here..."
                                                            />
                                                            {errors.educations?.[index]?.board_name && (
                                                                <span className="fs-8 text-danger">
                                                                    Board Name/University is required
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="form-label">
                                                                Specialization
                                                                <span className="fs-8 text-danger">*</span>{" "}
                                                            </label>

                                                            <textarea
                                                                {...register(`educations.${index}.specialization`, {
                                                                    required: true,
                                                                })}
                                                                defaultValue=""
                                                                className="form-control"
                                                                id="text-forms-specialization"
                                                                type="text"
                                                                placeholder="Enter Specialization here..."
                                                            />
                                                            {errors.educations?.[index]?.specialization && (
                                                                <span className="fs-8 text-danger">
                                                                    Specialization is required
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="form-label">
                                                                Percentage
                                                                {selectedEducationType !== "Certification" && (
                                                                    <span className="fs-8 text-danger">*</span>
                                                                )}
                                                            </label>
                                                            <input
                                                                {...register(`educations.${index}.percentage`, {
                                                                    required: selectedEducationType !== "Certification",
                                                                    pattern: {
                                                                        value: /^[0-9]+(\.[0-9]+)?$/,
                                                                        message: "Enter a valid number or decimal",
                                                                    },
                                                                })}
                                                                defaultValue=""
                                                                className="form-control"
                                                                id="number-forms-percentage"
                                                                type="text"
                                                                maxLength="6"
                                                                placeholder="Enter your Percentage/Cgpa"
                                                                onWheel={(e) => e.target.blur()}
                                                            />
                                                            {errors.educations?.[index]?.percentage &&
                                                                errors.educations?.[index]?.percentage.type ===
                                                                "required" && (
                                                                    <span className="fs-8 text-danger">
                                                                        Percentage is required
                                                                    </span>
                                                                )}

                                                            {errors.educations?.[index]?.percentage &&
                                                                errors.educations?.[index]?.percentage.type ===
                                                                "pattern" && (
                                                                    <span className="fs-8 text-danger">
                                                                        Percentage has to be a number
                                                                    </span>
                                                                )}
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <div className="d-flex justify-content-between">
                                                                <label className="form-label">Marksheet</label>
                                                                {test.marksheet ?
                                                                    <div className="preview">
                                                                        <a
                                                                            href={`https://fsc-rms-prod.s3.ap-southeast-1.amazonaws.com/media/${test.marksheet}`}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                        >
                                                                            Preview
                                                                        </a>
                                                                    </div> : ""}
                                                            </div>
                                                            <Controller
                                                                name={`educations.${index}.marksheet`}
                                                                control={control}
                                                                rules={{
                                                                    validate: (value) => {
                                                                        if (test.marksheet) return true;
                                                                        if (!value) return true;
                                                                        return value.type === "application/pdf";
                                                                    },
                                                                }}
                                                                defaultValue=""
                                                                render={({ field }) => (
                                                                    <input
                                                                        className="form-control"
                                                                        id="file-forms-marksheet"
                                                                        type="file"
                                                                        onChange={(e) => field.onChange(e.target.files[0])}
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                        {errors.educations?.[index]?.marksheet &&
                                                            errors.educations?.[index]?.marksheet.type === "validate" &&
                                                            !test.marksheet && (
                                                                <span className="fs-8 text-danger">
                                                                    Please upload your document in pdf format
                                                                </span>
                                                            )}
                                                    </div>

                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="form-label">Comments</label>
                                                            <textarea
                                                                {...register(`educations.${index}.comments`)}
                                                                defaultValue=""
                                                                className="form-control"
                                                                id="textarea-forms-comments"
                                                                placeholder="Enter Comments related to education"
                                                            />
                                                        </div>
                                                    </div>
                                                    {index > 0 && (
                                                        <div className="col-lg-4 align-self-center">
                                                            <button
                                                                type="button"
                                                                className="btn btn-danger"
                                                                id="button-button-deleteeducation"

                                                                onClick={() => removeDetails(index, test.id)}
                                                            >
                                                                <em class="icon ni ni-trash-alt"></em>
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ))}

                                        <div className="col-12 d-flex align-center">
                                            <h4 className="title">To add Educational Details click on "Add"</h4>
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                id="button-button-addeducation"
                                                onClick={() =>
                                                    append({
                                                        education_type: "",
                                                        passing_year: "",
                                                        board_name: "",
                                                        specialization: "",
                                                        percentage: "",
                                                        marksheet: "",
                                                        comments: "",
                                                    })
                                                }
                                            >
                                                Add
                                            </button>
                                        </div>
                                        <div className="form-group">
                                            <button
                                                type="button"
                                                className="btn btn-lg btn-light me-3"
                                                id="button-button-closeprofessional"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                                onClick={handleModalClose}>
                                                Cancel
                                            </button>
                                            {isLoading ? (
                                                <button
                                                    id="button-loading"
                                                    className="btn text-dark"
                                                    disabled >
                                                    Loading...
                                                </button>
                                            ) : (
                                                <button
                                                    type="submit"
                                                    className="btn btn-lg btn-primary"
                                                    id="button-button-submit"
                                                >
                                                    Submit
                                                </button>
                                            )}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    {submitMessage && (
                        <div
                            className="example-alerts position-fixed bottom-0 end-0 p-3"
                            style={{ zIndex: 100 }}
                        >
                            <div className="gy-4">
                                <div className="example-alert">
                                    <div
                                        className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                                    >
                                        <em
                                            className={`icon ni ni-${submitMessage.icon}-circle`}
                                        ></em>{" "}
                                        <strong>{submitMessage.message}</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default EditEducational;