import React, { useEffect, useState, useContext } from "react";
import { CandidateEntriesContext } from "../../../Context/CandidateEntriesContext";
import { useLocation, useNavigate } from "react-router-dom";
import EditPersonal from "./EditPersonal";
import EditProfessional from "./EditProfessional";
import EditEducational from "./EditEducational";

const CandidateOtherDetails = () => {
  const navigate = useNavigate()
  const location = useLocation()
  console.log(location)

  const {
    getCandidateOtherData,
    candidateOtherDetails,
    isDetailsLoading
  } = useContext(CandidateEntriesContext);

  console.log(candidateOtherDetails)

  useEffect(() => {
    getCandidateOtherData(location?.state?.editpost?.id, location.state.from);
  }, [location]);
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            {isDetailsLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
          <div class="nk-block-head-content">
            <a
              id="back-button"
              className="btn btn-light d-none d-sm-inline-flex"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
              <span>Back</span>
            </a>
            <a
              id="back-button"
              className="btn btn-icon btn-light d-inline-flex d-sm-none"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
            </a>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="card">
            <div className="card-aside-wrap">
              <div className="card-content">
                <div className="card-inner">
                  {/* Personal Details */}
                  <div className="nk-block">
                    <div className="nk-block-head d-flex justify-between">
                      <h4 className="title">
                        Personal Details
                      </h4>
                      {location.state.from === 'inprocess' &&
                        <button
                          className="btn btn-sm btn-primary"
                          id="button-button-personal_details"
                          data-bs-toggle="modal"
                          data-bs-target="#personal"
                        >
                          Edit personal details
                        </button>
                      }
                    </div>
                    <div className="profile-ud-list">
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Candidate Name</span>
                          <span className="profile-ud-value">
                            {candidateOtherDetails?.personal_details?.first_name && candidateOtherDetails?.personal_details?.first_name}
                            {" "}

                            {candidateOtherDetails?.personal_details?.middle_name && candidateOtherDetails?.personal_details?.middle_name}
                            {" "}

                            {candidateOtherDetails?.personal_details?.last_name &&
                              candidateOtherDetails?.personal_details?.last_name}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Email</span>
                          <span className="profile-ud-value">
                            {candidateOtherDetails?.personal_details?.email || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Mobile Number</span>
                          <span className="profile-ud-value">
                            {candidateOtherDetails?.personal_details?.mobile || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Gender</span>
                          <span className="profile-ud-value">
                            {candidateOtherDetails?.personal_details?.gender || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Current Location</span>
                          <span className="profile-ud-value">
                            {candidateOtherDetails?.personal_details?.current_location || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Date of Birth</span>
                          <span className="profile-ud-value">
                            {candidateOtherDetails?.personal_details?.date_of_birth || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Attachment</span>
                          <span className="profile-ud-value">
                            {candidateOtherDetails?.personal_details?.attachment ? <a href={candidateOtherDetails?.personal_details?.attachment} class="caption-text text-break"> View Attachment </a> : "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Skills</span>
                          <span className="profile-ud-value">
                            {candidateOtherDetails?.personal_details?.skills || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Instagram Url</span>
                          <span className="profile-ud-value">
                            {candidateOtherDetails?.personal_details?.instagram_url ? <a href={candidateOtherDetails?.personal_details?.instagram_url} className="caption-text text-break" target="_blank"> Link </a> : "-"}
                          </span>
                        </div>
                      </div>

                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            Twitter Link
                          </span>
                          <span className="profile-ud-value">
                            {candidateOtherDetails?.personal_details?.twitter_url ? <a href={candidateOtherDetails?.personal_details?.twitter_url} className="caption-text text-break" target="_blank"> Link </a> : "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            Facebook Link
                          </span>
                          <span className="profile-ud-value">
                            {candidateOtherDetails?.personal_details?.facebook_url ? <a href={candidateOtherDetails?.personal_details?.facebook_url} className="caption-text text-break" target="_blank"> Link </a> : "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Linkedin Link</span>
                          <span className="profile-ud-value">
                            {candidateOtherDetails?.personal_details?.linkedin_url ? <a href={candidateOtherDetails?.personal_details?.linkedin_url} className="caption-text text-break" target="_blank"> Link </a> : "-"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="border-bottom my-4" />

                  {/* Professional Details */}
                  <div className="nk-block">
                    <div className="nk-block-head d-flex justify-between">
                      <h4 className="title">
                        Professional Details
                      </h4>
                      {location.state.from === 'inprocess' &&
                        <button
                          className="btn btn-sm btn-primary"
                          id="button-button-professional_details"
                          data-bs-toggle="modal"
                          data-bs-target="#professional"
                        >
                          Edit professional details
                        </button>
                      }
                    </div>
                    {candidateOtherDetails?.professional_details?.length == 0 && (
                      <div className="profile-ud-list ">
                        <div className="profile-ud-item">
                          <span className="profile-ud-label">
                            Professional Details Not Found
                          </span>
                        </div>
                      </div>
                    )}

                    <div className="py-4 border-css">
                      <div className="profile-ud-list">
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Notice Period</span>
                            <span className="profile-ud-value">
                              {candidateOtherDetails?.other_details
                                ?.[0]?.notice_period
                                ?
                                candidateOtherDetails?.other_details
                                  ?.[0]?.notice_period + " " + "days"
                                :
                                '-'
                              }
                            </span>
                          </div>
                        </div>

                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Last Drawn CTC</span>
                            <span className="profile-ud-value">
                              {candidateOtherDetails?.other_details
                                ?.[0]?.current_salary
                                ?
                                candidateOtherDetails?.other_details
                                  ?.[0]?.current_salary + " " + "lakhs per annum"
                                :
                                "-"
                              }
                            </span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Expected CTC</span>
                            <span className="profile-ud-value">
                              {candidateOtherDetails?.other_details
                                ?.[0]?.expected_salary
                                ?
                                candidateOtherDetails?.other_details
                                  ?.[0]?.expected_salary + " " + "lakhs per annum"
                                :
                                "-"
                              }
                            </span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Total Work Experience</span>
                            <span className="profile-ud-value">
                              {candidateOtherDetails?.other_details
                                ?.[0]?.experience
                                ?
                                candidateOtherDetails?.other_details
                                  ?.[0]?.experience + " " + "years"
                                :
                                "-"
                              }
                            </span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Total Relevant Experience</span>
                            <span className="profile-ud-value">
                              {candidateOtherDetails?.other_details
                                ?.[0]?.relevant_experience
                                ? candidateOtherDetails?.other_details
                                  ?.[0]?.relevant_experience + " " + "years"
                                :
                                "-"
                              }
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {candidateOtherDetails?.professional_details?.length > 0 && (
                      candidateOtherDetails?.professional_details?.map((prof, index) => (
                        <>
                          <div className="py-4 border-css">
                            <div className="profile-ud-list">
                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">
                                    Previously Worked
                                  </span>
                                  <span className="profile-ud-value">
                                    {prof?.previously_worked || "-"}
                                  </span>
                                </div>
                              </div>
                              {prof?.previously_worked !== "No" && (
                                <>
                                  <div className="profile-ud-item">
                                    <div className="profile-ud wider">
                                      <span className="profile-ud-label">
                                        Designation
                                      </span>
                                      <span className="profile-ud-value">
                                        {prof?.profile_name || "-"}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="profile-ud-item">
                                    <div className="profile-ud wider">
                                      <span className="profile-ud-label">
                                        Organization Name
                                      </span>
                                      <span className="profile-ud-value">
                                        {prof?.organization_name || "-"}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="profile-ud-item">
                                    <div className="profile-ud wider">
                                      <span className="profile-ud-label">
                                        Date of Joining
                                      </span>
                                      <span className="profile-ud-value">
                                        {prof?.start_date || "-"}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="profile-ud-item">
                                    <div className="profile-ud wider">
                                      <span className="profile-ud-label">
                                        Last Working Day
                                      </span>
                                      <span className="profile-ud-value">
                                        {prof?.end_date || "-"}
                                      </span>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </>
                      )))}
                  </div>

                  <div className="border-bottom my-4" />

                  {/* Educational Details */}
                  <div className="nk-block">
                    <div className="nk-block-head d-flex justify-between">
                      <h4 className="title">
                        Educational Details
                      </h4>
                      {location.state.from === 'inprocess' &&
                        <button
                          className="btn btn-sm btn-primary"
                          id="button-button-educational_details"
                          data-bs-toggle="modal"
                          data-bs-target="#educational"
                        >
                          Edit educational details
                        </button>
                      }
                    </div>
                    {candidateOtherDetails?.educational_details?.length == 0 && (
                      <div className="profile-ud-list ">
                        <div className="profile-ud-item">
                          <span className="profile-ud-label">
                            Educational Details Not Found
                          </span>
                        </div>
                      </div>
                    )}
                    {candidateOtherDetails?.educational_details?.length > 0 && (
                      candidateOtherDetails?.educational_details?.map((edu, index) => (
                        <div className="py-4 border-css">
                          <div className="profile-ud-list ">
                            <div className="profile-ud-item">
                              <div className="profile-ud wider">
                                <span className="profile-ud-label">
                                  Board Name
                                </span>
                                <span className="profile-ud-value">
                                  {edu?.board_name || "-"}
                                </span>
                              </div>
                            </div>
                            <div className="profile-ud-item">
                              <div className="profile-ud wider">
                                <span className="profile-ud-label">
                                  Passing Year
                                </span>
                                <span className="profile-ud-value">
                                  {edu?.passing_year || "-"}
                                </span>
                              </div>
                            </div>
                            <div className="profile-ud-item">
                              <div className="profile-ud wider">
                                <span className="profile-ud-label">
                                  Percentage
                                </span>
                                <span className="profile-ud-value">
                                  {edu?.percentage || "-"}
                                </span>
                              </div>
                            </div>


                            <div className="profile-ud-item">
                              <div className="profile-ud wider">
                                <span className="profile-ud-label">
                                  Marksheet
                                </span>
                                <span className="profile-ud-value">
                                  {edu?.marksheet ? <a href={`https://fsc-rms-prod.s3.ap-southeast-1.amazonaws.com/media/${edu?.marksheet}`} class="caption-text text-break"> View Marksheet </a> : "-"}
                                </span>
                              </div>
                            </div>

                            <div className="profile-ud-item">
                              <div className="profile-ud wider">
                                <span className="profile-ud-label">
                                  Qualification
                                </span>
                                <span className="profile-ud-value">
                                  {edu?.education_type.label || "-"}
                                </span>
                              </div>
                            </div>

                            <div className="profile-ud-item">
                              <div className="profile-ud wider">
                                <span className="profile-ud-label">
                                  Specialization
                                </span>
                                <span className="profile-ud-value">
                                  {edu?.specialization || "-"}
                                </span>
                              </div>
                            </div>

                            <div className="profile-ud-item">
                              <div className="profile-ud wider">
                                <span className="profile-ud-label">
                                  Comments
                                </span>
                                <span className="profile-ud-value">
                                  {edu?.comments || "-"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )))}
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EditPersonal editpost={location.state?.editpost} candidateData={candidateOtherDetails?.personal_details} />
      <EditProfessional editpost={location.state?.editpost} candidateData={candidateOtherDetails} />
      <EditEducational editpost={location.state?.editpost} candidateData={candidateOtherDetails?.educational_details} />
    </>
  )
}

export default CandidateOtherDetails

