import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../../../Constant";
import { DashboardContext } from "../../../Context/DashboardContext";
import Swal from "sweetalert2";
import { UserDetailsContext } from "../../../Context/UserDetailsContext";

const ProfessionalInformation = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);
  const candidate_job_id = secureLocalStorage.getItem("candidate_info");
  const { getPendingData, getAppliedData } = useContext(DashboardContext);

  const { candidateData,
    getProfessionalDetails } = useContext(UserDetailsContext)

  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors, isDirty },
    reset,
    watch,
    getValues,
  } = useForm({
    defaultValues: {
      experiences: [
        {
          profile_name: "",
          organization_name: "",
          start_date: "",
          end_date: "",
          comments: "",
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "experiences",
    keyName: "key"
  });
  const userID = secureLocalStorage.getItem("userID");

  const resume_id = secureLocalStorage.getItem("resume_id");

  const watchPreviouslyWorked = watch("previously_worked");

  const [isEditable, setIsEditable] = useState(
    candidateData === null || candidateData?.professional_details?.length === 0
  );

  const toggleEdit = () => {
    setIsEditable(!isEditable);
  };

  const handleFormSubmit = (data) => {
    if (isDirty) {
      handleSubmit(onSubmit)(data);
    } else {
      props.handleNext();
    }
  };

  useEffect(() => {
    getProfessionalDetails();
  }, []);

  const [detailToBeRemoved, setDetailToBeRemoved] = useState([])


  const removeDetails = (index, detail_id) => {
    if (detail_id) {
      Swal.fire({
        text: "Are you sure? You will not be able to recover this data!",
        showDenyButton: true,
        confirmButtonText: "Yes",
        denyButtonText: `No, cancel!`,
      }).then((result) => {
        if (result.isConfirmed) {
          setDetailToBeRemoved([...detailToBeRemoved, detail_id])
          remove(index)
        }
      });
    } else {
      remove(index)
    }

  }

  const onSubmit = (data) => {
    console.log(data)
    if (candidateData.professional_details.length > 0) {
      var professional = JSON.stringify({
        professional_details: data.experiences.map((pro) => {
          return {
            id: pro.id,
            profile_name: pro.profile_name,
            organization_name: pro.organization_name,
            start_date: pro.start_date || null,
            end_date: pro.end_date || null,
            comments: pro.comments,
            candidate_to_be_process_id: secureLocalStorage.getItem("candidate_info"),
            previously_worked: data.previously_worked.value,
          };
        }),
        created_by_id: userID,
        updated_by_id: userID,
        details_to_be_removed: detailToBeRemoved
      });

      const url = VARIABLES.url + `/api/career-professionaldetails/`;

      let config = {
        method: "patch",
        maxBodyLength: Infinity,
        url: url,
        headers: {
          "Content-Type": "application/json",
        },
        data: professional,
      };

      setIsLoading(true);
      axios.request(config)
        .then((response) => {
          if (data.previously_worked.value === "Yes") {
            AdditionalDataSubmit(data);
          } else {
            props.handleNext();
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Failed to update professional details"
          })
        });
    } else {
      var professional = JSON.stringify({
        professional_details: data.experiences.map((pro) => {
          return {
            profile_name: pro.profile_name,
            organization_name: pro.organization_name,
            start_date: pro.start_date || null,
            end_date: pro.end_date || null,
            comments: pro.comments,
            previously_worked: data.previously_worked.value,
            candidate_to_be_process_id: secureLocalStorage.getItem("candidate_info"),
          };
        }),
        created_by_id: userID,
        updated_by_id: userID,
        details_to_be_removed: detailToBeRemoved
      });

      const url = VARIABLES.url + "/api/career-professionaldetails/";

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: url,
        headers: {
          "Content-Type": "application/json",
        },
        data: professional,
      };

      setIsLoading(true);
      axios.request(config)
        .then((response) => {
          if (data.previously_worked.value === "Yes") {
            AdditionalDataSubmit(data);
          } else {
            props.handleNext();
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Failed to update professional details"
          })
        });
    }
  };

  const AdditionalDataSubmit = (data) => {
    var candidate_info = JSON.stringify({
      job_opening: props.JobID,
      user_id: userID,
      notice_period: data?.notice_period,
      current_previous_company: data.experiences?.[0]?.organization_name,
      current_salary: data?.current_ctc,
      expected_salary: data?.expected_ctc,
      experience: data?.experience,
      relevant_experience: data.relevant_experience,
      updated_by_id: userID,
    });

    const candidate_to_be_process_id = secureLocalStorage.getItem("candidate_info");

    const url = VARIABLES.url + "/api/candidatetobeprocess/" + candidate_to_be_process_id + "/";
    console.log(url);
    let config = {
      method: "patch",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: candidate_info,
    };

    axios
      .request(config)
      .then((response) => {
        setIsLoading(false);
        getProfessionalDetails();
        Swal.fire({
          icon: "success",
          title: "You have succesfully updated your Professional details"
        }).then((result) => {
          if (result.isConfirmed) {
            props.handleNext();
          }
        });
        // getPendingData();
        // getAppliedData();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Failed to update professional details"
        })
      });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  useEffect(() => {
    const storedData = candidateData.professional_details;
    console.log(storedData);
    console.log(candidateData.other_details?.[0]);
    if (storedData?.length > 0) {
      let values = {};
      values.experiences = storedData;
      values.previously_worked = { value: storedData?.[0]?.previously_worked, label: storedData?.[0]?.previously_worked }
      values.notice_period = candidateData.other_details?.[0]?.notice_period;
      values.current_previous_company = candidateData.other_details?.[0]?.current_previous_company;
      values.current_ctc = candidateData.other_details?.[0]?.current_salary;
      values.expected_ctc = candidateData.other_details?.[0]?.expected_salary;
      values.experience = candidateData.other_details?.[0]?.experience;
      values.relevant_experience = candidateData.other_details?.[0]?.relevant_experience;
      reset(values);
    } else if (storedData?.length === 0) {
      let values = {};
      values.experiences = [
        {
          profile_name: "",
          organization_name: "",
          start_date: "",
          end_date: "",
          comments: "",
        },
      ]
      reset(values);
    }
  }, [candidateData]);

  return (
    <>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="row gs-4">
              <div className="col-lg-6">
                <div className="form-group">
                  <label className="form-label">
                    Previously Worked ?{" "}
                    <span className="fs-8 text-danger">*</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={[
                          { value: "Yes", label: "Yes" },
                          { value: "No", label: "No" },
                        ]}
                        isClearable={true}
                        id="select-forms-previously_worked"
                        isDisabled={!!candidateData.professional_details?.[0]?.previously_worked && !isEditable}
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name={`previously_worked`}
                    control={control}

                  />
                  {errors.previously_worked && (
                    <span className="fs-8 text-danger">
                      Previously Worked is required
                    </span>
                  )}
                </div>
              </div>
            </div>

            {watchPreviouslyWorked?.value === "Yes" && (
              <>
                <div className="row gs-4">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        Total Work Experience
                        <span className="fs-8 text-danger"> *</span>
                      </label>
                      <div className="input-group">
                        <input
                          {...register("experience", { required: true })}
                          defaultValue=""
                          className="form-control"
                          id="number-forms-experience"
                          type="tel"
                          placeholder="Total years of experience"
                          onWheel={(e) => e.target.blur()}
                          disabled={!!candidateData.other_details?.[0]?.experience && !isEditable}
                        />
                        <span class="input-group-text" id="basic-addon2">
                          year
                        </span>
                        {errors.experience &&
                          errors.experience.type === "required" && (
                            <span className="fs-8 text-danger">
                              Experience is required
                            </span>
                          )}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        Total Relevant Experience
                        <span className="fs-8 text-danger"> *</span>
                      </label>
                      <div className="input-group">
                        <input
                          {...register("relevant_experience", { required: true })}
                          defaultValue=""
                          className="form-control"
                          id="number-forms-relevant_experience"
                          type="tel"
                          placeholder="Total Relevant Experience"
                          onWheel={(e) => e.target.blur()}
                          disabled={!!candidateData.other_details?.[0]?.relevant_experience && !isEditable}
                        />
                        <span class="input-group-text" id="basic-addon2">
                          year
                        </span>
                        {errors.relevant_experience &&
                          errors.relevant_experience.type === "required" && (
                            <span className="fs-8 text-danger">
                              Total Relevant Experience is required
                            </span>
                          )}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        Expected CTC{" "}
                        <span className="fs-8 text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <input
                          {...register("expected_ctc", { required: true })}
                          defaultValue=""
                          className="form-control"
                          id="number-forms-expected_ctc"
                          type="tel"
                          placeholder="Expected CTC in lakhs per annum "
                          onWheel={(e) => e.target.blur()}
                          disabled={!!candidateData.other_details?.[0]?.expected_salary && !isEditable}
                        />
                        <span class="input-group-text" id="basic-addon2">
                          lakhs per annum
                        </span>
                        {errors.expected_ctc && (
                          <span className="fs-8 text-danger">
                            Expected CTC is required
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row gs-4 pb-3">
                  <div className="col-lg-12 col-md-12">
                    <h4 className="title">Current/Previous Company Details</h4>
                  </div>
                </div>
                <div className="row gs-4">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        Notice Period
                        <span className="fs-8 text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <input
                          {...register("notice_period", { required: true })}
                          defaultValue=""
                          className="form-control"
                          id="number-forms-notice_period"
                          type="tel"
                          placeholder="Enter notice period in days"
                          onWheel={(e) => e.target.blur()}
                          disabled={!!candidateData.other_details?.[0]?.notice_period && !isEditable}
                        />
                        <span class="input-group-text" id="basic-addon2">
                          days
                        </span>

                        {errors.notice_period && (
                          <span className="fs-8 text-danger">
                            Notice Period is required
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        Last Drawn CTC
                        <span className="fs-8 text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <input
                          {...register("current_ctc", { required: true })}
                          defaultValue=""
                          className="form-control"
                          id="number-forms-current_ctc"
                          type="tel"
                          placeholder="Current CTC in lakhs per annum"
                          onWheel={(e) => e.target.blur()}
                          disabled={!!candidateData.other_details?.[0]?.current_ctc && !isEditable}
                        />
                        <span class="input-group-text" id="basic-addon2">
                          lakhs per annum
                        </span>
                        {errors.current_ctc && (
                          <span className="fs-8 text-danger">
                            Current CTC is required
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                </div>
              </>
            )}
            {fields.map((item, index) => (
              <div key={item}>
                {watchPreviouslyWorked?.value === "Yes" && (
                  <div className="row gy-4 border-bottom border-0">
                    <div className="form-group" style={{ display: "none" }}>
                      <input
                        {...register(`experiences.${index}.id`)}
                        className="form-control"
                        type="text" hidden
                      />
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="form-group">
                        <label className="form-label">
                          Organization Name{" "}
                          <span className="fs-8 text-danger">*</span>
                        </label>
                        <input
                          {...register(
                            `experiences.${index}.organization_name`,
                            {
                              required: true,
                            }
                          )}
                          className="form-control"
                          type="text"
                          placeholder="Enter Organization Name"
                          disabled={!!candidateData.professional_details?.[0]?.organization_name && !isEditable}
                        />
                        {errors.experiences?.[index]?.organization_name && (
                          <span className="fs-8 text-danger">
                            Organization Name is required
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="form-group">
                        <label className="form-label">
                          Designation
                          <span className="fs-8 text-danger">*</span>
                        </label>
                        <input
                          {...register(`experiences.${index}.profile_name`, {
                            required: true,
                          })}
                          className="form-control"
                          type="text"
                          placeholder="Enter Profile Name"
                          disabled={!!candidateData.professional_details?.[0]?.profile_name && !isEditable}
                        />
                        {errors.experiences?.[index]?.profile_name && (
                          <span className="fs-8 text-danger">
                            Profile Name is required
                          </span>
                        )}
                      </div>
                    </div>



                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Date of Joining
                          <span className="fs-8 text-danger">*</span>
                        </label>
                        <input
                          {...register(`experiences.${index}.start_date`, {
                            required: true,
                          })}
                          defaultValue=""
                          className="form-control"
                          id="date-forms-start_date"
                          type="date"
                          placeholder="Enter Start Date"
                          disabled={!!candidateData.professional_details?.[0]?.start_date && !isEditable}
                        />
                        {errors.experiences?.[index]?.start_date && (
                          <span className="fs-8 text-danger">
                            Start date is required
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Last Working Day
                        </label>
                        <input
                          {...register(`experiences.${index}.end_date`, {
                            // required: "End date is required",
                            validate: (value) => {
                              if (!value) return true;
                              const startDate = new Date(
                                getValues(`experiences.${index}.start_date`)
                              );
                              const endDate = new Date(value);
                              return (
                                endDate >= startDate ||
                                "End date should not be before date of joining"
                              );
                            },
                          })}
                          // {...register(`experiences.${index}.end_date`, {
                          //   required: "End date is required",
                          //   validate: (value) => {
                          //     const startDate = new Date(
                          //       getValues(`experiences.${index}.start_date`)
                          //     );
                          //     const endDate = new Date(value);
                          //     return (
                          //       endDate >= startDate ||
                          //       "End date should not be before start date"
                          //     );
                          //   },
                          // })}
                          defaultValue=""
                          className="form-control"
                          id="date-forms-end_date"
                          type="date"
                          placeholder="Enter End Date"
                          disabled={!!candidateData.professional_details?.[0]?.end_date && !isEditable}
                        />
                        {/* {errors.experiences?.[index]?.end_date && (
                            <span className="fs-8 text-danger">
                              End date is required
                            </span>
                          )} */}
                        {errors.experiences?.[index]?.end_date && (
                          <span className="fs-8 text-danger">
                            {errors.experiences[index].end_date.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="form-label">Comments</label>
                        <textarea
                          {...register(`experiences.${index}.comments`)}
                          className="form-control"
                          rows="4"
                          placeholder="Enter Comments"
                          disabled={!!candidateData.professional_details?.[0]?.comments && !isEditable}
                        />
                      </div>
                    </div>

                    {index > 0 && (
                      <div className="col-lg-4">
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => removeDetails(index, item.id)}
                        >
                          <em class="icon ni ni-trash-alt"></em>
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}

            {watchPreviouslyWorked?.value === "Yes" && (
              <div className="col-12 ">
                <h4 className="title mt-3">To add Professional Details click on "Add"</h4>
                <button
                  type="button"
                  className="btn btn-primary mt-4"
                  onClick={() =>
                    append({
                      profile_name: "",
                      organization_name: "",
                      start_date: "",
                      end_date: "",
                      comments: "",
                    })
                  }
                >
                  Add
                </button>
              </div>)}

            <div className="d-flex justify-between p-4 mt-4">
              <button
                type="button"
                onClick={() => { props.handlePrev() }}
                className="btn btn-primary"
                id="button-button-previous"
              >
                Previous
              </button>
              <div className="d-flex">
                <>
                  <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn btn-sm btn-primary"
                      onClick={toggleEdit}
                    >
                      {isEditable ? "Cancel" : "Edit"}<em className="icon ni ni-pen2 ms-1"></em>
                    </button>
                  </div>
                </>
                {isLoading ? (
                  <button className="btn text-dark" disabled>
                    Loading...
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-primary"
                    id="submit-button-professionalinformation"
                  >
                    Next
                  </button>
                )}
              </div>
            </div>

            {submitMessage && (
              <div
                className={`alert alert-${submitMessage.type} mt-4`}
                role="alert"
              >
                <i className={`bi bi-${submitMessage.icon} me-2`}></i>
                {submitMessage.message}
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default ProfessionalInformation;
