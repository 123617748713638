import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { VARIABLES } from "../../../Constant";
import { useLocation, useNavigate } from "react-router-dom";
import { TypeMasterContext } from "../../../Context/TypeMaster";
import Swal from "sweetalert2";
import secureLocalStorage from "react-secure-storage";
import { CandidateEntriesContext } from "../../../Context/CandidateEntriesContext";

const EditPersonal = (props) => {
    console.log("props: ", props)

    const { gender } = useContext(TypeMasterContext);
    const { getCandidateOtherData } = useContext(CandidateEntriesContext);

    const {
        handleSubmit,
        control,
        register,
        formState: { errors },
        reset,
    } = useForm({
        mode: "onChange",
        defaultValues: {
            gender: { value: "", label: "" }
        }
    });

    const location = useLocation();
    console.log(location)
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [submitMessage, setSubmitMessage] = useState(null);

    const editedJobPost = props?.candidateData;

    const userID = secureLocalStorage.getItem("userID");

    const TodayDate = new Date();
    const maxDate = new Date();
    maxDate.setFullYear(TodayDate.getFullYear() - 14);
    const maxValidDate = maxDate.toISOString().split("T")[0];

    const handleModalClose = () => {
        reset();
    };

    const onSubmit = (data) => {
        let user = new FormData();
        user.append("date_of_birth", data?.date_of_birth);
        user.append("mobile", data?.mobile);
        user.append(
            "attachment",
            props?.editpost?.[0]?.attachment
                ? props?.editpost?.[0]?.attachment
                : data?.attachment
        );
        user.append("skills", data?.skills);
        user.append("current_location", data?.current_location);
        user.append("date_of_birth", data?.date_of_birth);
        user.append("gender", data?.gender.value);
        user.append("linkedin_url", data?.linkedin_url);
        user.append("facebook_url", data?.facebook_url);
        user.append("instagram_url", data?.instagram_url);
        user.append("twitter_url", data?.twitter_url);
        user.append("candidate_job_id", props?.editpost?.id);
        user.append("updated_by_id", userID);

        const url =
            VARIABLES.url + "/api/userdetails/" + userID + "/?send_from=career";
        let config = {
            method: "patch",
            maxBodyLength: Infinity,
            url: url,
            data: user,
        };

        setIsLoading(true);
        axios
            .request(config)
            .then((response) => {
                setIsLoading(false);
                secureLocalStorage.setItem(
                    "resume_id",
                    response.data.data[0].candidate_resume_id
                );
                // getCandidateOtherData(location?.state?.editpost?.id)
                Swal.fire({
                    icon: "success",
                    title: "You have successfully updated your personal details",
                }).then(() => {
                    document.getElementById("button-button-closepersonal").click();
                });
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error);
                Swal.fire({
                    icon: "cross",
                    title: "Failed to update personal details"
                })
            });
    };

    useEffect(() => {
        if (editedJobPost) {
            let phoneNumber = editedJobPost?.mobile;
            if (phoneNumber?.startsWith("+91")) {
                phoneNumber = phoneNumber.slice(3);
            }
            var defaultValues = {};
            defaultValues.mobile = phoneNumber;
            defaultValues.current_location = editedJobPost?.current_location;
            defaultValues.skills = editedJobPost?.skills === null ? "" : editedJobPost?.skills;
            defaultValues.linkedin_url = editedJobPost.linkedin_url === null ? "" : editedJobPost.linkedin_url;
            defaultValues.facebook_url = editedJobPost.facebook_url === null ? "" : editedJobPost.facebook_url;
            defaultValues.instagram_url = editedJobPost.instagram_url === null ? "" : editedJobPost.instagram_url;
            defaultValues.twitter_url = editedJobPost.twitter_url === null ? "" : editedJobPost.twitter_url;
            defaultValues.date_of_birth = editedJobPost?.date_of_birth;
            if (editedJobPost?.gender !== null) {
                const getGender = {
                    value: editedJobPost?.gender,
                    label: editedJobPost?.gender,
                };
                defaultValues.gender = getGender;
            } else {
                defaultValues.gender = null;
            }
            reset(defaultValues);
        }
    }, [editedJobPost]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setSubmitMessage(null);
        }, 5000);

        return () => clearTimeout(timeoutId);
    }, [submitMessage]);

    return (
        <>
            <div
                className="modal fade"
                id="personal"
                aria-hidden="true"
                aria-labelledby="exampleModalToggleLabel2"
                tabindex="-1"
            >
                <div className="modal-dialog">
                    <div className="modal-content w-100">
                        <div className="modal-header">
                            <h4 class="modal-title">Edit personal details</h4>
                            <button
                                type="button"
                                className="btn-close"
                                id="button-button-closepersonal"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={handleModalClose}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row gy-4">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="form-label">
                                                Date Of Birth <span className="fs-8 text-danger">*</span>
                                            </label>
                                            <input
                                                {...register("date_of_birth", {
                                                    required: true,
                                                })}
                                                defaultValue=""
                                                className="form-control"
                                                id="date-form-date_of_birth"
                                                type="date"
                                                placeholder="Enter your date of birth..."
                                                max={maxValidDate}
                                            />
                                            {errors.date_of_birth &&
                                                errors.date_of_birth.type === "required" && (
                                                    <span className="fs-8 text-danger">
                                                        Date of birth is required
                                                    </span>
                                                )}
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="form-label">
                                                Mobile Number <span className="fs-8 text-danger">*</span>
                                            </label>
                                            <input
                                                {...register("mobile", {
                                                    required: true,
                                                    maxLength: 10,
                                                    minLength: 10,
                                                    validate: {
                                                        startsWithValidDigit: (value) =>
                                                            ["9", "8", "7", "6"].includes(value.toString().charAt(0)) || "First digit should be 9, 8, 7, or 6",
                                                    },
                                                })}
                                                defaultValue=""
                                                className="form-control"
                                                id="number-forms-mobile"
                                                type="number"
                                                placeholder="Enter mobile number here..."
                                                onWheel={(e) => e.target.blur()}
                                            />
                                            {errors.mobile && errors.mobile.type === "required" && (
                                                <span className="fs-8 text-danger">
                                                    Mobile number is required
                                                </span>
                                            )}
                                            {errors.mobile && errors.mobile.type === "minLength" && (
                                                <span className="fs-8 text-danger">
                                                    Mobile number should be at least 10 digits
                                                </span>
                                            )}
                                            {errors.mobile && errors.mobile.type === "maxLength" && (
                                                <span className="fs-8 text-danger">
                                                    Mobile number should not exceed 10 digits
                                                </span>
                                            )}
                                            {errors.mobile && errors.mobile.type === "startsWithValidDigit" && (
                                                <span className="fs-8 text-danger">
                                                    {errors.mobile.message}
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group">
                                            {editedJobPost?.attachment ? (
                                                <>
                                                    <div className="d-flex justify-content-between">
                                                        <label className="form-label">Resume</label>
                                                        <div className="preview">
                                                            <a
                                                                href={editedJobPost.attachment}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                Preview
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <Controller
                                                        name="attachment"
                                                        control={control}
                                                        defaultValue=""
                                                        rules={{
                                                            validate: (value) =>
                                                                !value || value.type === "application/pdf",
                                                        }}
                                                        render={({ field }) => (
                                                            <input
                                                                className="form-control"
                                                                id="file-forms-attachments"
                                                                type="file"
                                                                onChange={(e) => field.onChange(e.target.files[0])}
                                                            />
                                                        )}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <label className="form-label">
                                                        Resume <span className="fs-8 text-danger">*</span>
                                                    </label>
                                                    <Controller
                                                        name="attachment"
                                                        control={control}
                                                        defaultValue=""
                                                        rules={{
                                                            required: true,
                                                            validate: (value) =>
                                                                value?.type === "application/pdf",
                                                        }}
                                                        render={({ field }) => (
                                                            <input
                                                                className="form-control"
                                                                id="file-forms-attachments"
                                                                type="file"
                                                                onChange={(e) => field.onChange(e.target.files[0])}
                                                            />
                                                        )}
                                                    />
                                                    {errors.attachment &&
                                                        errors.attachment.type === "required" && (
                                                            <span className="fs-8 text-danger">
                                                                Resume is required
                                                            </span>
                                                        )}
                                                    {errors.attachment &&
                                                        errors.attachment.type === "validate" && (
                                                            <span className="fs-8 text-danger">
                                                                Please upload your document in pdf format
                                                            </span>
                                                        )}
                                                </>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="form-label">
                                                {" "}
                                                Current Location <span className="fs-8 text-danger">*</span>
                                            </label>
                                            <input
                                                {...register("current_location", { required: true })}
                                                defaultValue=""
                                                className="form-control"
                                                id="text-forms-current_location"
                                                type="text"
                                                placeholder="Enter your current location"
                                            />
                                            {errors.current_location && (
                                                <span className="fs-8 text-danger">
                                                    Current location is required
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="form-label">
                                                Gender <span className="fs-8 text-danger">*</span>
                                            </label>
                                            <Controller
                                                name="gender"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        options={gender}
                                                        id="select-forms-gender"
                                                        isSearchable={true}
                                                        isClearable={true}
                                                        defaultValue=""
                                                    />
                                                )}
                                            />
                                            {errors.gender && (
                                                <span className="fs-8 text-danger">Gender is required</span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="form-label">Skills</label>
                                            <input
                                                {...register("skills")}
                                                defaultValue=""
                                                type="text"
                                                className="form-control"
                                                id="textarea-forms-skills"
                                                placeholder="Enter your skills here..."
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="form-label">Linkedin url</label>
                                            <input
                                                {...register("linkedin_url")}
                                                defaultValue=""
                                                className="form-control"
                                                id="url-forms-linkedin_url"
                                                type="url"
                                                placeholder="Enter your linkedin url here..."
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="form-label">Facebook url</label>
                                            <input
                                                {...register("facebook_url")}
                                                defaultValue=""
                                                className="form-control"
                                                id="url-forms-facebook_url"
                                                type="url"
                                                placeholder="Enter your facebook url here..."
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="form-label">Instagram url</label>
                                            <input
                                                {...register("instagram_url")}
                                                defaultValue=""
                                                className="form-control"
                                                id="url-forms-instagram_url"
                                                type="url"
                                                placeholder="Enter your instagram url here..."
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="form-label">Twitter url</label>
                                            <input
                                                {...register("twitter_url")}
                                                defaultValue=""
                                                className="form-control"
                                                id="url-forms-twitter_url"
                                                type="url"
                                                placeholder="Enter your twitter url here..."
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <button
                                            type="button"
                                            className="btn btn-lg btn-light me-3"
                                            id="button-button-closeprofessional"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            onClick={handleModalClose}>
                                            Cancel
                                        </button>
                                        {isLoading ? (
                                            <button
                                                id="button-loading"
                                                className="btn text-dark"
                                                disabled >
                                                Loading...
                                            </button>
                                        ) : (
                                            <button
                                                type="submit"
                                                className="btn btn-lg btn-primary"
                                                id="button-button-submit"
                                            >
                                                Submit
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div>
                    {submitMessage && (
                        <div
                            className="example-alerts position-fixed bottom-0 end-0 p-3"
                            style={{ zIndex: 100 }}
                        >
                            <div className="gy-4">
                                <div className="example-alert">
                                    <div
                                        className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                                    >
                                        <em
                                            className={`icon ni ni-${submitMessage.icon}-circle`}
                                        ></em>{" "}
                                        <strong>{submitMessage.message}</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default EditPersonal;